import axios, { AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults } from "axios";

export abstract class BaseApiClient {
    client: AxiosInstance;
    clientName: string;
    constructor(baseUrl: string, clientName: string) {
        this.client = axios.create({
            baseURL: baseUrl
        } as CreateAxiosDefaults);
        this.clientName = clientName;
    }

    // Set the authentication header for the client here
    abstract setClientAuthentication(): Promise<void>;
    // Example: this.client.defaults.headers.common["Authorization"] = /* <-Token-> */

    async get<CResponse /*Custom Response*/>(path: string, params: { [key: string]: any }): Promise<CResponse> {
        await this.setClientAuthentication();
        const response = await this.client.get<CResponse>(path, { params } as AxiosRequestConfig);
        return response.data;
    }

    async post<CResponse /*Custom Response*/>(path: string, body: { [key: string]: any }): Promise<CResponse> {
        await this.setClientAuthentication();
        const response = await this.client.post<CResponse>(path, body);
        return response.data;
    }

    async put<CResponse /*Custom Response*/>(path: string, body: { [key: string]: any }): Promise<CResponse> {
        await this.setClientAuthentication();
        const response = await this.client.put<CResponse>(path, body);
        return response.data;

    }

    async delete(path: string): Promise<{ success: boolean }> {
        await this.setClientAuthentication();
        await this.client.delete(path);
        return { success: true };
    }
}