import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps, Theme } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';

interface LoaderButtonProps {
    onClick: () => void;
    isLoading?: boolean;
    disabled?: boolean;
    buttonText: string;
    color?: OverridableStringUnion<
        'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
        ButtonPropsColorOverrides
    >;
    sx?: SxProps<Theme>;
}

const LoaderButton: React.FC<LoaderButtonProps> = ({ onClick, isLoading, disabled, color, buttonText, sx }) => {
    return (
        <Button
            variant="contained"
            color={color}
            onClick={onClick}
            disabled={disabled}
            sx={sx}
        >
            {isLoading && <CircularProgress size={24} color="inherit" />}
            {!isLoading && buttonText}
        </Button>
    );
};

export default LoaderButton;
