import { InvoiceTypes } from "../invoice/invoice-types";

export namespace OfferTypes {

    export type OffersState = {
        offers: OfferData[];
        isLoading: boolean;
        errorMessage?: string;
    }

    export enum OfferStatus {
        ACTIVE = "ACTIVE",
        CLAIMED = "CLAIMED",
        REDEEMED = "REDEEMED",
        EXPIRED = "EXPIRED",
    }

    export type OfferData = {
        offerId: string;
        status: OfferStatus;
        title: string;
        subTitle: string;
        offerEndAt: string;
        bannerImageUrl?: string;
        redeemBefore?: string;
        claimedAt?: string;
        expiredAt?: string;
        couponCode?: string;
        redemptionDetails?: [{
            redeemedAt: string;
            storeName: string;
            customerInvoiceId: string;
        }]
        isLoading?: boolean;
        claimedInThisSession?: boolean;
        invoice?: InvoiceTypes.Invoice;
    }
}