import { Box, CardMedia, Container, Divider, TableFooter, ThemeProvider, Typography, createTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DateUtils } from '../../utils/date-utils';
import { InvoiceTypes } from './invoice-types';

const theme = createTheme({
    typography: {
        body1: {
            color: 'black'
        },
        body2: {
            color: 'black'
        },
        subtitle1: {
            color: 'black'
        },
    }
});

const Invoice = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const queryParams = new URLSearchParams(location.search);
    const invoice = JSON.parse(queryParams.get('invoiceData')!) as InvoiceTypes.Invoice;
    useEffect(() => {
        if (containerRef.current) {
            // Set margins
            const margin = 10;
            const pdfWidth = 210; // A4 width
            const pdfHeight = 297; // A4 height

            html2canvas(containerRef.current).then((canvas) => {
                const imgData = canvas.toDataURL('image/png', 1.0);

                // Calculate PDF page size based on A4 dimensions
                const pdf = new jsPDF({
                    orientation: 'p',
                    unit: 'mm',
                    format: 'a4',
                    putOnlyUsedFonts: true,
                    compress: true,
                });

                // Add the image to the PDF
                pdf.addImage(imgData, 'PNG', margin, margin, pdfWidth - 2 * margin, pdfHeight - 2 * margin); // A4 dimensions

                // Download the PDF
                pdf.save('invoice.pdf');
                navigate(-1);
            });
        }

    });
    return (
        <ThemeProvider theme={theme}>
            <Container ref={containerRef} style={{ width: "210mm", height: "297mm" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    m: 2,
                }}>
                    <CardMedia
                        component="img"
                        alt="logo"
                        sx={{
                            width: 128,
                        }}
                        image={require('../../../src/assets/brand_image.png')}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1" fontWeight='fontWeightMedium' color={'black'}>Tax Invoice</Typography>
                    <Typography variant="subtitle1"><Box fontWeight='fontWeightMedium' display='inline'>Date&ensp;</Box>{DateUtils.getHumanReadableDate(new Date(invoice.createdAt))}</Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography variant="subtitle1" fontWeight='fontWeightMedium'>
                            SuperK - {invoice.storeName}
                        </Typography>
                        <Typography variant="subtitle1"><Box fontWeight='fontWeightMedium' display='inline'>GSTIN&ensp;</Box>37CBGPG1939K1ZF</Typography>

                    </Box>
                    <Box>
                        <Typography variant="subtitle1"><Box fontWeight='fontWeightMedium' display='inline'>Invoice ID&ensp;</Box>{invoice.id}</Typography>
                        <Typography variant="subtitle1"><Box fontWeight='fontWeightMedium' display='inline'>Customer&ensp;</Box>{invoice.customerPhoneNumber}</Typography>
                        <Typography variant="subtitle1"><Box fontWeight='fontWeightMedium' display='inline'>Payment Mode&ensp;</Box>Cash</Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Typography variant="body1">Address</Typography>
                        <Typography variant="body2">
                            SuperK - {invoice.storeName}
                            <br />
                            {invoice.storeAddress.addressLine1}
                            <br />
                            {invoice.storeAddress.addressLine2}
                            <br />
                            {invoice.storeAddress.city}, {invoice.storeAddress.pincode}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: "flex-end" }}>
                        <Typography variant="body1">Contact</Typography>
                        <Typography variant="body2">
                            +91 40 4521 0677
                            <br />
                            +91 85271 41637
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Product&nbsp;(HSN)</TableCell>
                                <TableCell align="right">MRP</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Qty</TableCell>
                                <TableCell align="right">Discount</TableCell>
                                <TableCell align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoice.items.map((row, index) => (
                                <TableRow key={row.variantId}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.productName}&nbsp;<br />({row.hsnCode})</TableCell>
                                    <TableCell align="right">{row.mrp}</TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                    <TableCell align="right">{row.qty}</TableCell>
                                    <TableCell align="right">{row.discountAmount}</TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell rowSpan={3} colSpan={5} />
                                <TableCell>Subtotal</TableCell>
                                <TableCell align="right">{invoice.subTotalBeforeTax}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Tax</TableCell>
                                <TableCell align="right">{invoice.taxAmount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={1}>Grand Total</TableCell>
                                <TableCell align="right">₹ {invoice.grandTotal}</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell colSpan={5} />
                                <TableCell>Total Savings</TableCell>
                                <TableCell align="right">₹ {invoice.totalSavings}</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Typography variant="body2">This is Computer Generated Invoice</Typography>
                </Box>
            </Container >
        </ThemeProvider>

    );
};

export default Invoice;