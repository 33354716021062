import { Box, CircularProgress } from "@mui/material"

const LoadingScreen = () => {
    return <div>
        <Box sx={{ height: "100%", minHeight: "100vh", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={24} color="inherit" />
        </Box>
    </div>
}

export default LoadingScreen