import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import PLACE_HOLDER from '../../../assets/offer-banner-place-holder.png';
import { DateUtils } from '../../../utils/date-utils';
import LoaderButton from '../../util-components/buttons/loading-button';
import { claimOffer, getInvoice } from '../offer-actions';
import { OfferTypes } from '../offer-types';
import CouponCodeDialog from './coupon-code-dialog';
import { PinpointHandler } from '../../../app/pinpoint-handler';

export type OfferProps = {
    data: OfferTypes.OfferData;
}

const OfferCard: React.FC<OfferProps> = ({ data }) => {
    const navigate = useNavigate();

    const downloadInvoicePDF = async () => {
        PinpointHandler.recordEvent("offers-screen", {"event": "download_invoice_button_clicked", "invoice_id": data.redemptionDetails ? data.redemptionDetails[0].customerInvoiceId : ""});
        await dispatch(getInvoice(data.redemptionDetails![0].customerInvoiceId));
    }
    const viewInvoice = () => {
        PinpointHandler.recordEvent("offers-screen", {"event": "view_invoice_button_clicked", "invoice_id": data.redemptionDetails ? data.redemptionDetails[0].customerInvoiceId : ""});
        navigate(`/invoice?invoiceData=${JSON.stringify(data.invoice)}`);
    }
    const [couponCodeDialogOpen, setCouponCodeDialogOpen] = React.useState(false);
    const dispatch = useAppDispatch();
    const onCouponCodeClick = () => {
        PinpointHandler.recordEvent("offers-screen", {"event": "coupon_code_button_clicked", "coupon_code": data.couponCode ?? ""});
        setCouponCodeDialogOpen(true);
    }
    const onCouponCodeDialogClose = () => {
        setCouponCodeDialogOpen(false);
    }
    const onClaimNowClick = async () => {
        // Dispatch the claimOffer action
        PinpointHandler.recordEvent("offers-screen", {"event": "claim_offer_button_clicked", "offerId": data.offerId});
        await dispatch(claimOffer(data.offerId));

        // Check if the offer was claimed successfully and has a coupon code
        if (data.status === OfferTypes.OfferStatus.CLAIMED && data.couponCode) {
            // Open the CouponCodeDialog with the received coupon code
            setCouponCodeDialogOpen(true);
        }
    };

    return (
        <Grid sx={{ margin: 1 }}>
            <Card sx={{ display: 'flex', maxWidth: 512, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }} >
                <CardMedia
                    component="img"
                    sx={{
                        width: "50%",
                    }}
                    image={data.bannerImageUrl ?? PLACE_HOLDER}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                    <CardContent>
                        <Typography gutterBottom={true} sx={{ fontWeight: "bold", fontSize: 12 }}>
                            {data.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ fontSize: 10 }}>
                            {data.subTitle}
                        </Typography>
                    </CardContent>
                    <Box sx={{ display: "flex", flexDirection: 'column', pl: 2, pr: 2 }}>
                        {data.status === OfferTypes.OfferStatus.ACTIVE && <Typography variant="body2" color="text.secondary" sx={{ fontSize: 10 }}>
                            Expiry {data.offerEndAt}
                        </Typography>}
                        {data.status === OfferTypes.OfferStatus.CLAIMED && <Typography variant="body2" color="text.secondary" sx={{ fontSize: 10 }}>
                            Expiry: {data.redeemBefore}
                        </Typography>}
                        {data.status === OfferTypes.OfferStatus.REDEEMED && <Typography variant="body2" color="text.secondary" sx={{ fontSize: 10 }}>
                            {DateUtils.getHumanReadableDate(new Date(data.redemptionDetails![0].redeemedAt))}
                        </Typography>}
                        {data.status === OfferTypes.OfferStatus.REDEEMED && <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', fontSize: 10 }}>
                            {data.redemptionDetails![0].storeName}
                        </Typography>}
                        {data.status === OfferTypes.OfferStatus.EXPIRED && <Typography variant="body2" color="red" sx={{ display: 'flex', fontSize: 10 }}>
                            Expired on {data.expiredAt}
                        </Typography>}

                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: "flex-end", p: 1 }}>
                        {data.status === OfferTypes.OfferStatus.CLAIMED
                            // ? <Button variant="contained" sx={{ width: '100%' }} onClick={onCouponCodeClick}>Coupon Code</Button>
                            ? <LoaderButton
                                buttonText="Coupon Code"
                                color='success'
                                onClick={onCouponCodeClick}
                                sx={{ width: '100%' }}
                            />
                            : data.status === OfferTypes.OfferStatus.ACTIVE ?
                                // <Button variant="contained" style={{ width: '100%' }} onClick={() => dispatch(claimOffer(data.offerId))}>Claim Now</Button>
                                <LoaderButton
                                    isLoading={data.isLoading}
                                    disabled={data.isLoading}
                                    buttonText="Claim Now"
                                    onClick={onClaimNowClick}
                                    sx={{ width: '100%' }}
                                />
                                : data.status === OfferTypes.OfferStatus.REDEEMED ?
                                    data.invoice === undefined ? <LoaderButton
                                        disabled={false}
                                        isLoading={data.isLoading}
                                        buttonText="Invoice  ⬇️"
                                        onClick={downloadInvoicePDF}
                                        sx={{ width: '100%' }}
                                    /> : <LoaderButton
                                        disabled={false}
                                        isLoading={data.isLoading}
                                        color='success'
                                        buttonText="View Invoice"
                                        onClick={viewInvoice}
                                        sx={{ width: '100%' }}
                                    /> : <div></div>
                        }
                    </Box>
                </Box>
            </Card>
            <CouponCodeDialog open={couponCodeDialogOpen} onClose={onCouponCodeDialogClose} couponCode={data.couponCode ?? "NA"} />
        </Grid >
    )
}

export default OfferCard;