import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Login from '../pages/login/login';
import OffersScreen from "../pages/offers/offers";
import Invoice from '../pages/invoice/invoice';
import RedirectToApp from '../pages/redirect-to-app/redirect-to-app';

export type RouteType = {
    path: string;
    icon?: JSX.Element;
    text: string;
    element: JSX.Element;
    addInDrawer: boolean;
    isAuthRequired: boolean;
};

export const ConfiguredRoutes: RouteType[] = [
    {
        path: '/',
        icon: <LocalOfferIcon />,
        text: 'Offers',
        element: <OffersScreen />,
        addInDrawer: true,
        isAuthRequired: true
    },
    {
        path: '/login',
        text: 'Login',
        element: <Login />,
        addInDrawer: false,
        isAuthRequired: false
    },
    {
        path: '/invoice',
        text: 'Invoice',
        element: <Invoice />,
        addInDrawer: false,
        isAuthRequired: false
    },
    {
        path: '/redirect-to-app',
        text: 'Download On PlayStore',
        element: <RedirectToApp />,
        addInDrawer: false,
        isAuthRequired: false
    }
];