import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginHandler } from "../login/login-handler";

const RequiresAuth = ({ children }: { children: JSX.Element }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        LoginHandler.updatePinpointEndPoint();
        setAuthenticated(true);
      } catch (error) {
        // Handle any errors that may occur during the authentication process
        sessionStorage.setItem('redirectedFromToLogin', location.pathname);
        // Redirect to the login page or take other appropriate action
        navigate('/login');
      }
    };

    checkAuthentication();
  }, [navigate, location.pathname]);

  // If the authentication status is still null, you can show a loading indicator or null
  if (authenticated === null) {
    return null; // or return a loading indicator
  }

  // If the user is authenticated, render the protected content (children)
  if (authenticated) {
    return <>{children}</>;
  }

  return null; // Return null for unauthenticated users
}

export default RequiresAuth;