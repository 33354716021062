import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../pages/login/login-slice";
import offersReducer from "../pages/offers/offer-slice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    offers: offersReducer,
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'login/getOtp/fulfilled',
          'login/verifyOtp/fulfilled',
        ],
        ignoredPaths: ['login.user'],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch