export class DateUtils {
    static getHumanReadableDate(date: Date) {
        return date.toLocaleString(
            'en-IN', {
            timeZone: 'Asia/Kolkata',
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }
        )
    }
}