import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CognitoUser } from "amazon-cognito-identity-js";
import { getOtp, verifyOtp } from './login-actions';
import { LoginState } from './login-types';

const initialState: LoginState = {
    isLoggedIn: false,
    isLoading: false,
    isWaitingForOtp: false,
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setPhoneNumber: (state, action: PayloadAction<string>) => {
            state.phoneNumber = action.payload
        },
        setOtp: (state, action: PayloadAction<string>) => {
            state.otp = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOtp.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOtp.fulfilled, (state, action: PayloadAction<CognitoUser>) => {
                state.isWaitingForOtp = true;
                state.user = action.payload;
                state.errorMessage = undefined;
                state.isLoading = false;
            })
            .addCase(getOtp.rejected, (state, action) => {
                state.errorMessage = action.error.message || 'Something went wrong';
                state.isLoading = false;
            })
            .addCase(verifyOtp.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(verifyOtp.fulfilled, (state, action: PayloadAction<boolean>) => {
                state.isLoggedIn = action.payload;
                state.errorMessage = undefined;
                state.isWaitingForOtp = false;
                state.isLoading = false;
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.errorMessage = action.error.message || 'Something went wrong';
                state.isLoading = false;
            })

    }
})

// Action creators are generated for each case reducer function
export const { setPhoneNumber, setOtp } = loginSlice.actions

export default loginSlice.reducer