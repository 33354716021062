import { createAsyncThunk } from "@reduxjs/toolkit";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from 'aws-amplify';
import { LoginHandler } from "./login-handler";

Auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
})

export const getOtp = createAsyncThunk(
    'login/getOtp',
    async (phoneNumber: string) => {
        const user = await LoginHandler.signInAndSignUp(phoneNumber)
        return user;
    })
export const verifyOtp = createAsyncThunk(
    'login/verifyOtp',
    async ({ user, otp }: { user: CognitoUser, otp: string }) => {
        const res = await LoginHandler.verifyOtp(user, otp)
        return res;
    }
)