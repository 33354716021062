import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Button,
  CardMedia,
  CardContent,
  Card,
  IconButton,
} from "@mui/material";
import { useEffect } from "react";
import { PinpointHandler } from "../../app/pinpoint-handler"

function RedirectToApp() {
  useEffect(() => {});
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "90vh",
        }}
      >
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CardMedia
              component="img"
              alt="logo"
              sx={{
                width: '50%', alignSelf: 'center',
                margin: '0 auto',
              }}
              image={require('../../../src/assets/brand_image.png')}
            />
        <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ mt: 4 }}
            >
              This website is deprecated. Please download the Superk app for the best experience.
            </Typography>
            <IconButton
              color="primary"
              onClick={() => {
                PinpointHandler.recordEvent("app", {
                  event: "download_superk_app",
                });
                window.location.href =
                  "http://play.google.com/store/apps/details?id=in.localbuy.superk.customer.superk_customer";
              }}
              sx={{ mt: 2 }}
            >
              <CardMedia
                component="img"
                alt="icon"
                sx={{ width: 150, height: 50 }}
                image={require('../../../src/assets/play-store-button.png')}
              />
              {/* You can also use an existing icon component instead of CardMedia */}
            </IconButton>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default RedirectToApp;
