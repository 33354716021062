import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OtpInput from './components/otp-input';
import "./login.css";
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { setOtp, setPhoneNumber } from './login-slice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect } from 'react';
import { getOtp, verifyOtp } from './login-actions';
import LoaderButton from '../util-components/buttons/loading-button';
import { Box, Button, Card, CardContent, CardMedia, Container, CssBaseline, Typography } from '@mui/material';
import { PinpointHandler } from '../../app/pinpoint-handler';
import { LoginHandler } from './login-handler';

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginStore = useAppSelector(state => state.login)
  useEffect(() => {
    PinpointHandler.recordEvent("login-screen", { "event": "login_screen_opened" });
    // Check for changes in isLoggedIn
    if (loginStore.isLoggedIn) {
      // After a successful login, check for the redirectedFromToLogin route in sessionStorage
      const intendedRoute = sessionStorage.getItem('redirectedFromToLogin');

      if (intendedRoute) {
        // Clear the intended route from sessionStorage
        sessionStorage.removeItem('redirectedFromToLogin');
        // Redirect the user to the redirectedFromToLogin route
        LoginHandler.updatePinpointEndPoint();
        navigate(intendedRoute);
      } else {
        // If there's no intended route, redirect to a default route
        navigate('/');
      }
    }
  }, [loginStore.isLoggedIn, navigate]);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '90vh',
        }}
      >
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CardMedia
              component="img"
              alt="logo"
              sx={{
                width: '50%', alignSelf: 'center',
                margin: '0 auto',
              }}
              image={require('../../../src/assets/brand_image.png')}
            />
            <Typography component="h2" variant="h5" sx={{ pt: 2, pb: 2 }}>
              Login
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
              Please login with phone number.
            </Typography>

            <PhoneInput
              country={'in'}
              value={loginStore.phoneNumber}
              onChange={(phoneNumber) => dispatch(setPhoneNumber(`+${phoneNumber}`))}
              countryCodeEditable={false}
              disabled={loginStore.isWaitingForOtp}
              specialLabel=''
            />
            {loginStore.isWaitingForOtp ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                // pt: 2,
              }}>
                <Button variant="text" onClick={() => {
                  PinpointHandler.recordEvent("login-screen", { "event": "resend_otp_clicked", "phoneNumber": loginStore.phoneNumber ?? "" });
                  dispatch(getOtp(loginStore.phoneNumber!));
                }}>Resend OTP</Button>
                <OtpInput
                  value={loginStore.otp ?? ""}
                  onChange={(value) => dispatch(setOtp(value))}
                  valueLength={4}
                />
                <LoaderButton
                  isLoading={loginStore.isLoading}
                  disabled={(loginStore.otp ? loginStore.otp.length !== 4 : true) || loginStore.isLoading}
                  buttonText="Verify OTP"
                  onClick={() => {
                    PinpointHandler.recordEvent("login-screen", { "event": "verify_otp_clicked", "phoneNumber": loginStore.phoneNumber ?? ""});
                    dispatch(verifyOtp({ user: loginStore.user!, otp: loginStore.otp! }))
                  }}
                  sx={{ mt: 2 }}
                />
              </Box>
            ) : (
              <div >
                <LoaderButton
                  isLoading={loginStore.isLoading}
                  disabled={loginStore.phoneNumber ? loginStore.phoneNumber.length < 13 : true || loginStore.isLoading}
                  buttonText="Get OTP"
                  onClick={() => {
                    PinpointHandler.recordEvent("login-screen", { "event": "get_otp_clicked", "phoneNumber": loginStore.phoneNumber ?? "" })
                    dispatch(getOtp(loginStore.phoneNumber!));
                  }}
                  sx={{ mt: 2 }}
                />
              </div>
            )}
            {loginStore.errorMessage ? (
              <Typography variant='caption' color='error' sx={{ pt: 2 }}>
                <FontAwesomeIcon icon={faCircleExclamation} />
                &nbsp;{loginStore.errorMessage}
              </Typography>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default Login;