import { CardMedia, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { PinpointHandler } from '../../app/pinpoint-handler';
import LoadingScreen from '../util-components/screens/loading-screen';
import OfferCard from './components/offers-card';
import { getOffers } from './offer-actions';
import { OfferTypes } from './offer-types';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const OffersScreen = () => {
    const [value, setValue] = React.useState(0);
    const dispatch = useAppDispatch();
    const offerStore = useAppSelector(state => state.offers);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 1) {
            PinpointHandler.recordEvent("offers-screen", { "event": "claimed-offers-tab-clicked" });
        } else {
            PinpointHandler.recordEvent("offers-screen", { "event": "active-offers-tab-clicked" });
        }
        setValue(newValue);
    };

    useEffect(() => {
        PinpointHandler.recordEvent("offers-screen", { "event": "page-loaded" });
        dispatch(getOffers());
    }, [dispatch]);

    const activeOffers = offerStore.offers.filter(offer => offer.status === OfferTypes.OfferStatus.ACTIVE || offer.claimedInThisSession);
    const claimedOffers = offerStore.offers.filter(offer => offer.status !== OfferTypes.OfferStatus.ACTIVE && !offer.claimedInThisSession);

    return (
        <div>
            {offerStore.isLoading ?
                <LoadingScreen />
                :
                <Box sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ backgroundImage: "url(https://pics.superk.in/assets/main_banner_background.png)", backgroundPosition: 'center', width: '100vw', height: "300px" }}>
                        <img src="https://pics.superk.in/assets/main_banner.png" alt="main_banner" style={{ height: "100%", objectFit: "cover", margin: "0 auto", paddingBlock: "16px" }} />
                    </div>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Active Offers" {...a11yProps(0)} />
                            <Tab label="Past Claimed Offers" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Box sx={{ flexGrow: 1 }}>
                            {activeOffers.length === 0 &&
                                <Container>
                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, textAlign: 'center' }}>
                                        No active offers
                                    </Typography>
                                </Container>}
                            <Grid container spacing={2}>
                                {activeOffers.map((data, index) => (
                                    <OfferCard key={data.offerId} data={data} />
                                ))}
                            </Grid>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Box sx={{ flexGrow: 1 }}>
                            {claimedOffers.length === 0 &&
                                <Container>
                                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, textAlign: 'center' }}>
                                        No past claimed offers
                                    </Typography>
                                </Container>}
                            <Grid container spacing={2}>
                                {claimedOffers.map((data, index) => (
                                    <OfferCard key={data.offerId} data={data} />
                                ))}
                            </Grid>
                        </Box>
                    </CustomTabPanel>
                </Box>
            }
        </div>
    );
};

export default OffersScreen;