import { createAsyncThunk } from "@reduxjs/toolkit";
import { OfferApi } from "../../axios/customer-backend-api-handlers/offers/offers-api";
import { Auth } from "aws-amplify";

export const getOffers = createAsyncThunk(
    "offers/getOffers",
    async () => {
        const customerId = (await Auth.currentAuthenticatedUser()).attributes.sub;
        const res = await OfferApi.getOffers(customerId);
        return res;
    }
)

export const claimOffer = createAsyncThunk(
    "offers/claimOffer",
    async (offerId: string) => {
        const customerId = (await Auth.currentAuthenticatedUser()).attributes.sub;
        const res = await OfferApi.claimOffer(customerId, offerId);
        return res;
    }
)

export const getInvoice = createAsyncThunk(
    "offers/getInvoice",
    async (invoiceId: string) => {
        const customerId = (await Auth.currentAuthenticatedUser()).attributes.sub;
        const res = await OfferApi.getInvoice(customerId, invoiceId);
        return res;
    }
)