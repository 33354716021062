import { InvoiceTypes } from "../../../pages/invoice/invoice-types";
import { OfferTypes } from "../../../pages/offers/offer-types";
import { customerBackendClient } from "../../customer-backend-client";

export class OfferApi {
    static async getOffers(customerId: string) {
        const res = await customerBackendClient.get<OfferTypes.OfferData[]>(`/customer/${customerId}/offers`, {})
        return res;
    }

    static async claimOffer(customerId: string, offerId: string) {
        const res = await customerBackendClient.post<OfferTypes.OfferData>(`/customer/${customerId}/offer/${offerId}/claim`, {})
        return res;
    }

    static async getInvoice(customerId: string, invoiceId: string) {
        const res = await customerBackendClient.get<InvoiceTypes.Invoice>(`/customer/${customerId}/invoice/${invoiceId}`, {})
        return res;
    }
}