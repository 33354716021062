import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import { ConfiguredRoutes } from './app/routes';
import NotFound from './pages/not-found/not-found';
import RequiresAuth from './pages/route-utils/requires-auth';
import { PinpointHandler } from './app/pinpoint-handler';
import { useEffect } from 'react';


const App = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  
  useEffect(() => {
    PinpointHandler.updateLocationPinpointEndPoint()
    const userAgent = navigator.userAgent;
    if ((/Android/i.test(userAgent))) {
      PinpointHandler.recordEvent("app", { "event": "redirect_to_play_store" })
      navigate('/redirect-to-app');
    }
  }, [navigate]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getCurrentRoute = () => {
    const currentRoute = ConfiguredRoutes.find((route) => route.path === location.pathname);
    return currentRoute ? currentRoute.text : 'Not Found';
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ background: '#2E3B55' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              PinpointHandler.recordEvent("app", { "event": "menu_button_clicked" });
              toggleDrawer();
            }}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {getCurrentRoute()}
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <Box
          sx={{
            p: 2,
            height: 1,
            // backgroundColor: "#dbc8ff",
          }}
        >
          <IconButton onClick={toggleDrawer} sx={{ mb: 2 }}>
            <CloseIcon />
          </IconButton>

          <Divider sx={{ mb: 2 }} />

          <Box sx={{ mb: 2 }}>
            {ConfiguredRoutes.filter((route) => route.addInDrawer).map((route) => (
              <ListItemButton
                key={route.path}
                component={Link}
                to={route.path}
                onClick={toggleDrawer}
              >
                <ListItemIcon>{route.icon}</ListItemIcon>
                <ListItemText primary={route.text} />
              </ListItemButton>
            ))}
          </Box>
        </Box>
      </Drawer>
      <Routes>
        {ConfiguredRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={
            route.isAuthRequired ?
              <RequiresAuth>
                {route.element}
              </RequiresAuth>
              :
              route.element
          } />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  );
};

export default App;
