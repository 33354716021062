import { Analytics } from "aws-amplify";

export class PinpointHandler {
    static updateLocationPinpointEndPoint() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async function (position) {
                Analytics.updateEndpoint({
                    location: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    },
                    userAttributes: {
                        location: [`${position.coords.latitude}`, `${position.coords.longitude}`],
                    }
                });
            });
        }
    }

    static updateUserAttributesPinpointEndPoint(userId: string, phone_number: string) {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async function (position) {
                Analytics.updateEndpoint({
                    location: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    },
                    userAttributes: {
                        user_id: [userId],
                        phone_number: [phone_number],
                        location: [`${position.coords.latitude}`, `${position.coords.longitude}`]
                    }
                });
            }, function (error) {
                Analytics.updateEndpoint({
                    userAttributes: {
                        user_id: [userId],
                        phone_number: [phone_number],
                    }
                });

            });
        } else {
            Analytics.updateEndpoint({
                userAttributes: {
                    user_id: [userId],
                    phone_number: [phone_number],
                }
            });
        }
    }
    static recordEvent(eventType: string, attributes: Record<string, string>) {
        Analytics.record({ name: eventType, attributes: attributes });
    }
}