import { Auth } from "aws-amplify";
import { BaseApiClient } from "./base-api-client";

class CustomerBackendClient extends BaseApiClient {

    async setClientAuthentication(): Promise<void> {
        const token = await Auth.currentSession();
        this.client.defaults.headers.common["Authorization"] = token.getAccessToken().getJwtToken();
    }
}
export const customerBackendClient = new CustomerBackendClient("https://beta.api.superk.in", "CustomerBackendClient");