import { Box, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import QRCode from "react-qr-code";

function CouponCodeDialog({ couponCode, open, onClose }: { couponCode: string, open: boolean, onClose: () => void }) {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, pb: 2 }}>
          <QRCode style={{ height: 128, width: 128 }} value={couponCode} />
        </Box>
        <Box alignItems="center" sx={{ display: "flex", flexDirection: "column" }} >
          <DialogTitle>
            {couponCode}
          </DialogTitle>
        </Box>
        <Divider variant="middle" />
        <Box maxWidth={256} alignItems="center" sx={{ display: "flex", flexDirection: "column", pt: 2 }} >
          <Typography variant="caption" textAlign="center">
            Show this <b>QR/Coupon code</b> at any SuperK store and redeem the offer.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CouponCodeDialog;