import { createSlice } from "@reduxjs/toolkit";
import { OfferTypes } from "./offer-types";
import { claimOffer, getInvoice, getOffers } from "./offer-actions";

const initialState: OfferTypes.OffersState = {
    offers: [],
    isLoading: true,
};

export const offerSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOffers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getOffers.fulfilled, (state, action) => {
                state.offers = action.payload;
                state.isLoading = false;
            })
            .addCase(getOffers.rejected, (state, action) => {
                state.errorMessage = action.error.message || 'Something went wrong';
                state.isLoading = false;
            })
            .addCase(claimOffer.pending, (state, action) => {
                state.offers = state.offers.map((offer) => {
                    if (offer.offerId === action.meta.arg) {
                        return {
                            ...offer,
                            isLoading: true,
                        }
                    }
                    return offer;
                });
            })
            .addCase(claimOffer.fulfilled, (state, action) => {
                //replace the action payload with the updated offer
                state.offers = state.offers.map((offer) => {
                    if (offer.offerId === action.payload.offerId) {
                        return { ...action.payload, claimedInThisSession: true, isLoading: false };
                    }
                    return offer;
                });
            })
            .addCase(claimOffer.rejected, (state, action) => {
                state.errorMessage = action.error.message || 'Something went wrong';
                state.offers = state.offers.map((offer) => {
                    if (offer.offerId === action.meta.arg) {
                        return {
                            ...offer,
                            isLoading: false,
                        }
                    }
                    return offer;
                });
            })
            .addCase(getInvoice.pending, (state, action) => {
                state.offers = state.offers.map((offer) => {
                    if (offer.redemptionDetails) {
                        if (offer.redemptionDetails.map((rd) => rd.customerInvoiceId).includes(action.meta.arg)) {
                            return {
                                ...offer,
                                isLoading: true,
                            }
                        }
                    }
                    return offer;
                });
            })
            .addCase(getInvoice.fulfilled, (state, action) => {
                state.offers = state.offers.map((offer) => {
                    if (offer.redemptionDetails) {
                        if (offer.redemptionDetails!.map((rd) => rd.customerInvoiceId).includes(action.payload.id)) {
                            return {
                                ...offer,
                                isLoading: false,
                                invoice: action.payload
                            };
                        }
                    }
                    return offer;
                });
            })
            .addCase(getInvoice.rejected, (state, action) => {
                state.errorMessage = action.error.message || 'Something went wrong';
                state.offers = state.offers.map((offer) => {
                    if (offer.offerId === action.meta.arg) {
                        return {
                            ...offer,
                            isLoading: false,
                        }
                    }
                    return offer;
                });
            })
    }
})

export default offerSlice.reducer