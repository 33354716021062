/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:7cc1b645-a4a6-4ba2-8d9f-53a1ea542379",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_FVgWvQdQ7",
    "aws_user_pools_web_client_id": "4amvn8e8beqig68fk0pntn0o4h",
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_mobile_analytics_app_id": "09bd0a2c7f5848aba0746723ea3b9cc3",
    "aws_mobile_analytics_app_region": "ap-south-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "09bd0a2c7f5848aba0746723ea3b9cc3",
            "region": "ap-south-1"
        }
    }
};


export default awsmobile;
