
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from 'aws-amplify';
import { PinpointHandler } from "../../app/pinpoint-handler";

export class LoginHandler {
    static async signInAndSignUp(phoneNumber: string) {
        try {
            const user = await this.signIn(phoneNumber);
            return user;
        } catch (error: any) {
            await this.signUp(phoneNumber);
            const user = await this.signIn(phoneNumber);
            return user;
        }
    }

    static async verifyOtp(user: CognitoUser, otp: string) {
        let res = await Auth.sendCustomChallengeAnswer(user, otp);
        if (res.signInUserSession) {
            this.updatePinpointEndPoint()
            return res;
        } else {
            throw new Error('Wrong OTP Please Try Again');
        }
    }

    private static async signIn(phoneNumber: string) {
        const user = (await Auth.signIn(phoneNumber)) as CognitoUser
        return user;
    }

    private static async signUp(phoneNumber: string) {
        await Auth.signUp({
            username: phoneNumber,
            password: Math.random().toString(10) + 'Abc#',
            attributes: {
                phone_number: phoneNumber,
            }
        });
    }

    static async signOut() {
        await Auth.signOut();
    }

    static async updatePinpointEndPoint() {
        const user = await Auth.currentAuthenticatedUser();
        PinpointHandler.updateUserAttributesPinpointEndPoint(user.attributes.sub, user.attributes.phone_number)
    }
}