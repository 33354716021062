import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Center vertically
        minHeight: '100vh', // Set the minimum height of the container to 100% of the viewport height
        p: 2
      }}
    >
      <img
        src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
        alt=""
        width={500} height={250}
      />
      <Typography variant="h6" sx={{ pt: 2, pb: 2 }}>
        The page you're looking for doesn't exist.
      </Typography>
      <Button variant="contained" onClick={() => navigate("/")}>Back Home</Button>
    </Box>
  );
};

export default NotFound;
